import { createContext, useContext } from "react";
import {
  CalendarItemForPlanFragment,
  PlanDiscountFragment,
  PlanFragment,
  PlanInvoicePreviewFragment,
  SubscriptionPurchaseResponse,
} from "../graphql/graphql-generated.types";

export interface PlanContextState {
  plan: PlanFragment;
  planDiscount?: PlanDiscountFragment;
  invoicePreview?: PlanInvoicePreviewFragment;
  transactionFee?: NonNullable<PlanInvoicePreviewFragment["fees"]>[number];
  amountDue: number;
  amountTax: number;
  discount: number;
  participant?: Participant;
  subscriptionUuid?: string;
  startDate?: string;
  paymentMethodId?: string;
  isFree?: boolean;
  skippedBooking: boolean;
  isLinkedAccount: boolean;
  planInvalidMessage?: {
    title?: string;
    message: string;
    buttonText?: string;
    buttonLink?: string;
  };
  previousScreen?: PlanScreen;
  participantType?: ParticipantType;
  participantTypeVisible: boolean;
  paymentSuccessVisible: boolean;
  bookingSuccessVisible: boolean;
  documentsSuccessVisible: boolean;
  bookedCalendarItemByUuid: { [key: string]: CalendarItem };
  paymentSuccessActions: UseToggle;
  bookingSuccessActions: UseToggle;
  documentsSuccessActions: UseToggle;
  paying: boolean;
  paymentMethodErrors: {
    [key: string]: boolean;
  };
  nextPaymentScreen: "booking" | "confirmation" | "documents";
  calendarItem?: CalendarItemForPlanFragment;
  bookingError: boolean;
  setParticipantType: (type: ParticipantType) => void;
  purchasePlan: (
    billing: string,
    paymentType: PaymentMethodType | "free",
    calendarItemId?: string,
  ) => unknown;
  setPayment: (payment: SubscriptionPurchaseResponse) => void;
  setParticipant: (participant?: Participant) => void;
  goToScreen: (screen: PlanScreen) => void;
  addNewParticipant: VoidFunction;
  setPaymentMethodId: (id: string) => void;
  setSkippedBooking: (value: boolean) => void;
  showMessageScreen: (message: string) => void;
  bookAnotherSession: VoidFunction;
  addBookedCalendarItem: (calendarItem: CalendarItem) => void;
  retryPaymentMethod: VoidFunction;
  goToAfterPayment: VoidFunction;
  today: Date;
  calendarEndDate: Date;
}

const PlanContext = createContext<PlanContextState | undefined>(undefined);

export const usePlanContext = () => {
  const contextValue = useContext(PlanContext);
  if (!contextValue) {
    throw new Error("usePlanContext must be used inside PlanContext");
  }
  return contextValue;
};

export default PlanContext;
