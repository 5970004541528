import { useTranslation } from "react-i18next";
import {
  Icons,
  Button,
  Modal,
  PopupPosition,
  RelativePopupWrapper,
  Menu,
  MenuItem,
} from "@pushpress/shared-components";
import { useAppContext } from "../../contexts/AppContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddPaymentModal from "../AddPaymentModal";
import CadPaymentModal from "../AddPaymentModal/CadPaymentModal";
import { useToggle } from "../../utils/hooks";
import { useMemo } from "react";

type PaymentButtonProps = {
  selectPaymentMethod: (p: PaymentMethod | null) => void;
};

const PaymentButton = ({ selectPaymentMethod }: PaymentButtonProps) => {
  const [paymentModalVisible, paymentModalActions] = useToggle(false);
  const [
    internationalPaymentModalVisible,
    internationalPaymentModalVisibleActions,
  ] = useToggle(false);
  const [paymentMethodChoiceMenu, paymentMethodChoiceMenuActions] =
    useToggle(false);
  const { t } = useTranslation("common");
  const {
    stripeOptions,
    refetchStripeOptions,
    client: { currencyIso },
  } = useAppContext();

  const handlePaymentClose = () => {
    refetchStripeOptions();
    paymentModalActions.off();
  };

  const handleInternationalPaymentClose = () => {
    refetchStripeOptions();
    internationalPaymentModalVisibleActions.off();
  };

  const stripePaymentOptions = useMemo(() => {
    /* 
        We can use 2 different stripe configuration, one for US payment methods, 
        and other for International payment methods, 
        that's why we have 2 ways to load stripe 
        PLATFORM = USA eg. CARD, US BANK TRANSFER, etc...
        CONNECT = International eg. BECS, SEPA, etc...
        Read more: https://www.notion.so/pushpress/Stripe-International-Banking-Technical-Documents-63f18b3657314102af417e32bf609b39#0117254cc9034c67adab31e8ec30f32d
    */
    const stripePromise = process.env.NEXT_PUBLIC_STRIPE_PK ? loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK) : null;
    const stripePaymentOptions = stripeOptions?.customerDetails.find(
      (customerDetail) => customerDetail.stripeAccountType === "platform",
    );
    const stripeInternationalPaymentOptions =
      stripeOptions?.customerDetails.find(
        (customerDetail) => customerDetail.stripeAccountType === "connect",
      );
    const stripeInternationalPromise = stripeInternationalPaymentOptions?.stripePublishableKey
      ? loadStripe(stripeInternationalPaymentOptions.stripePublishableKey)
      : undefined;

    return {
      stripePromise,
      stripePaymentOptions,
      stripeInternationalPaymentOptions,
      stripeInternationalPromise,
      stripeInternationalClientSecret:
        stripeInternationalPaymentOptions?.clientSecret,
    };
  }, [stripeOptions]);

  return (
    <>
      <Modal
        visible={paymentModalVisible}
        closeModal={paymentModalActions.off}
        disableClickAway
        disableEscapeKeyDown
      >
        {stripePaymentOptions.stripePaymentOptions && (
          <Elements
            stripe={stripePaymentOptions.stripePromise}
            options={stripePaymentOptions.stripePaymentOptions}
          >
            <AddPaymentModal
              stripeCustomerId={
                stripePaymentOptions.stripePaymentOptions.stripeCustomer
              }
              selectNewPaymentMethod={selectPaymentMethod}
              closeModal={handlePaymentClose}
            />
          </Elements>
        )}
      </Modal>
      <Modal
        visible={internationalPaymentModalVisible}
        closeModal={internationalPaymentModalVisibleActions.off}
        disableClickAway
        disableEscapeKeyDown
      >
        {stripePaymentOptions.stripeInternationalPaymentOptions &&
          stripePaymentOptions.stripeInternationalPromise && (
            <>
              {currencyIso === "CAD" &&
              stripePaymentOptions.stripeInternationalClientSecret ? (
                <CadPaymentModal
                  stripeCustomerId={
                    stripePaymentOptions.stripeInternationalPaymentOptions
                      .stripeCustomer
                  }
                  stripeSecret={
                    stripePaymentOptions.stripeInternationalClientSecret
                  }
                  stripe={stripePaymentOptions.stripeInternationalPromise}
                  selectNewPaymentMethod={selectPaymentMethod}
                  closeModal={handleInternationalPaymentClose}
                />
              ) : (
                <Elements
                  stripe={stripePaymentOptions.stripeInternationalPromise}
                  options={
                    stripePaymentOptions.stripeInternationalPaymentOptions
                  }
                >
                  <AddPaymentModal
                    stripeCustomerId={
                      stripePaymentOptions.stripeInternationalPaymentOptions
                        .stripeCustomer
                    }
                    selectNewPaymentMethod={selectPaymentMethod}
                    closeModal={handleInternationalPaymentClose}
                  />
                </Elements>
              )}
            </>
          )}
      </Modal>
      <RelativePopupWrapper
        popupComponent={
          <Menu>
            <MenuItem
              label={t("payment.card")}
              action={paymentModalActions.on}
            />
            <MenuItem
              label={t("payment.bank")}
              action={internationalPaymentModalVisibleActions.on}
            />
          </Menu>
        }
        setPopupVisibility={() => { paymentMethodChoiceMenuActions.toggle() }}
        popupVisible={paymentMethodChoiceMenu}
        popupPosition={PopupPosition.Bottom_Left_Inside}
      >
        <Button
          buttonType="secondary"
          size="large"
          text={t("payment.addPayment")}
          iconLocation="left"
          icon={Icons.Plus}
          onClick={() => {
            if (stripePaymentOptions.stripeInternationalPaymentOptions) {
              paymentMethodChoiceMenuActions.toggle();
            } else {
              paymentModalActions.on();
            }
          }}
        />
      </RelativePopupWrapper>
    </>
  );
};

export default PaymentButton;
